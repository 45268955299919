import { brandProjects } from 'constants/brand.constants';
import './our-projects.styles.scss';
import CustomButton from 'components/shared/buttons/custom-button/custom-button.component';

const OurProjects = () => {
  return (
    <section className='op-section'>
      <div className='op-content'>
        <div className='op-texts'>
          <h2 className='op-summary'>
            Our <span>Projects</span>
          </h2>
        </div>

        <div className='op-grid'>
          <div
            className='op-grid-item op-card'
            style={{ backgroundImage: `url(${brandProjects[0].imageUrl})` }}
          />
          {brandProjects.slice(0, 2).map((p) => (
            <div
              className='op-grid-item op-card'
              key={p.id}
              style={{ backgroundImage: `url(${p.imageUrl})` }}
            >
              <div className='op-dc-gradient' />
              <div className='op-dc-texts'>
                <h4 className='op-dc-title'>{p.title}</h4>

                <ul className='op-dc-description'>
                  {p.shortDetails.map((d) => (
                    <li key={d} className='op-dc-detail'>
                      {d}
                    </li>
                  ))}
                </ul>
                <span className='op-know-more' onClick={() => {}}>
                  Read More
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className='pt-4 w-full flex justify-center'>
          <CustomButton>View all</CustomButton>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;
