import { CSSProperties, FC } from 'react';

import './image-container.styles.scss';

interface Props {
  src: string;
  alt: string;
  height?: string | number;
  width?: string | number;
  minWidth?: string | number;
  objectFit?: CSSProperties['objectFit'];
}

const ImageContainer: FC<Props> = ({
  src,
  alt,
  objectFit,
  height = 'auto',
  width = 'auto',
  minWidth
}) => {
  return (
    <div
      className='image-container'
      style={{
        height,
        width,
        maxWidth: minWidth,
      }}
    >
      <img style={{ objectFit }} src={src} alt={alt} />
    </div>
  );
};

export default ImageContainer;
