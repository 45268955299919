import { rooftopSolarSolution } from 'constants/brand.constants';

import RooftopSolutionCard from './rooftop-solution/rooftop-solution-card.component';

import './rooftop-solutions.styles.scss';

const RooftopSolutions = () => {
  return (
    <section className='rts-section'>
      <div className='rts-row'>
        <h2 className='rts-summary'>
          Smart Rooftop <span>Solar Solutions</span>
        </h2>
        <p className='rts-subhead'>
          Harness the power of the sun with our smart rooftop solar solutions
          tailored to every need
        </p>

        <div className='rts-grid'>
          {rooftopSolarSolution.slice(0, 3).map((b) => (
            <RooftopSolutionCard key={b.id} solution={b} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RooftopSolutions;
