import classNames from 'classnames';
import NextButton from 'components/shared/buttons/next-button/next-button.component';
import PrevButton from 'components/shared/buttons/prev-button/prev-button.component';
import { blogs } from 'constants/brand.constants';
import { useState } from 'react';
import { Direction } from 'types/enums';
import { formatDate } from 'utils/strings.utils';
import './latest-blog.styles.scss';
import CardTag from 'components/shared/card-tag/card-tag.component';

const LatestBlogs = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleTestimonialChange = (direction: Direction): void => {
    setSelectedIndex((prev) => {
      switch (direction) {
        case Direction.BACKWARD:
          return (prev - 1 + blogs.length) % blogs.length;
        case Direction.FORWARD:
          return (prev + 1) % blogs.length;
      }
    });
  };

  const selectedBlog = blogs[selectedIndex];

  return (
    <section className='lb-section'>
      <div className='lb-content'>
        <div className='lb-carousel'>
          <div className='lb-carousel-arrow desktop'>
            <PrevButton
              onClick={() => handleTestimonialChange(Direction.BACKWARD)}
            />
          </div>

          <div className={classNames('lb-card')}>
            <div
              className='lbc-image'
              style={{
                backgroundImage: `url(${selectedBlog.imageUrl})`,
              }}
            ></div>

            <div className='lbc-content'>
              <div className='lbc-tags'>
                <CardTag title='LATEST' />
                {selectedBlog.blogType && (
                  <CardTag title={selectedBlog.blogType.title.toUpperCase()} />
                )}
              </div>
              <div className='lbc-date'>{formatDate(selectedBlog.date)}</div>
              <h2 className='lbc-title'>{selectedBlog.title}</h2>
              <p className='lbc-description'>{selectedBlog.description}</p>
              <a
                href={selectedBlog.link}
                target='_blank'
                rel='noreferrer'
                className='lbc-link'
              >
                Read more
              </a>
            </div>
          </div>

          <div className='lb-navigation-row mobile'>
            <PrevButton
              onClick={() => handleTestimonialChange(Direction.BACKWARD)}
            />
            <NextButton
              onClick={() => handleTestimonialChange(Direction.FORWARD)}
            />
          </div>

          <div className='lb-carousel-arrow desktop'>
            <NextButton
              onClick={() => handleTestimonialChange(Direction.FORWARD)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestBlogs;
