import { brandCertificates } from 'constants/brand.constants';
import './certificates.styles.scss';
import ImageContainer from 'components/shared/image-container/image-container.component';

const Certificates = () => {
  return (
    <section className='certi-section'>
      <div className='certi-content'>
        <h2 className='certi-summary'>
          Certifications upholding our<span> quality standards</span>
        </h2>
        <p className='certi-description'>
          The certifications we hold are crucial for maintaining our high
          standards of quality. These validate our commitment to excellence and
          ensure that our processes and products consistently meet or exceed
          industry benchmarks.
        </p>

        <div className='certificates-row'>
          {brandCertificates.map((c) => (
            <div key={c.id} className='certificate'>
              <ImageContainer
                src={c.imageUrl}
                alt={c.certificate}
                height='68px'
              />
              <p className='certificate-type'>{c.certificate}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Certificates;
