import BlogDetails from 'components/blog-details/blog-details.component';
import RelatedBlogs from 'components/blog-details/related-blogs/related-blogs.component';
import { useParams } from 'react-router-dom';

const BlogDetailsPage = () => {
  const { blogId } = useParams();

  return (
    <>
      <BlogDetails />
      <RelatedBlogs />
    </>
  );
};

export default BlogDetailsPage;
