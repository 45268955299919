import { FC } from 'react';
import { FaCheck } from 'react-icons/fa';

import './offering.styles.scss';

interface Props {
  title: string;
  description: string;
}

const Offering: FC<Props> = ({ title, description }) => {
  return (
    <div className='offering'>
      <div className='ofr-row'>
        <div className='of-icon'>
          <FaCheck />
        </div>
        <h5 className='of-title'>{title}</h5>
      </div>
      <p className='of-description'>{description}</p>
    </div>
  );
};

export default Offering;
