import backgroundUrl from 'assets/images/landing-bg.png';

import AboutUs from 'components/homepage/about-us/about-us.component';
import Blogs from 'components/homepage/blogs/blogs.component';
import Faqs from 'components/homepage/faqs/faqs.component';
import Manufactures from 'components/homepage/manufactures/manufactures.component';
import Projects from 'components/homepage/projects/projects.component';
import Solutions from 'components/homepage/solutions/solutions.component';
import Testimonials from 'components/testimonials/testimonials.components';
import Hero from 'components/shared/hero/hero.component';
import { brandFaqs } from 'constants/brand.constants';

const Homepage = () => {
  return (
    <>
      <Hero
        backgroundUrl={backgroundUrl}
        titleHighlight='Solar Solutions'
        titlePart1='Powering Your Future with '
        subTitle='Bhopal’s path to zero bills'
      />
      <AboutUs />
      <Solutions />
      <Manufactures />
      <Projects />
      <Testimonials />
      <Faqs faqs={brandFaqs} />
      <Blogs />
    </>
  );
};

export default Homepage;
