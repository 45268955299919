import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import './custom-button.styles.scss';

interface Props {
  color?: 'primary' | 'secondary';
  endIcon?: ReactNode;
  loading?: boolean;
}

type LocalProps = ButtonHTMLAttributes<HTMLButtonElement> & Props;

const CustomButton: FC<LocalProps> = ({
  color = 'secondary',
  endIcon,
  loading,
  ...buttonProps
}) => {
  if (loading) {
    return (
      <button className={`cu-cta ${color}`} {...buttonProps}>
        <span className='btn-loader'></span>
      </button>
    );
  }

  return (
    <button className={`cu-cta ${color}`} {...buttonProps}>
      {buttonProps.children}
      {endIcon}
    </button>
  );
};

export default CustomButton;
