import callUsBg from 'assets/images/residential/callusBg.png';
import heroBgUrl from 'assets/images/residential/residentialHero.png';

import Certificates from 'components/commercial/certificates/certificates.component';
import Form from 'components/commercial/form/form.component';
import OurProjects from 'components/commercial/our-projects/our-projects.component';
import WhyUs from 'components/commercial/why‐us/why‐us.component';
import Faqs from 'components/homepage/faqs/faqs.component';
import RooftopSolutions from 'components/residential/rooftop-solutions/rooftop-solutions.component';
import CallUsBanner from 'components/shared/call-us-banner/call-us-banner.component';
import Hero from 'components/shared/hero/hero.component';
import ServicesBenefits from 'components/shared/service-benefits/service-benefits.component';
import Testimonials from 'components/testimonials/testimonials.components';
import {
  residentialBenefits,
  residentialFAQS,
  residentialWhyUsStrings,
} from 'constants/residential.constants';

const Residential = () => {
  return (
    <>
      <Hero
        backgroundUrl={heroBgUrl}
        titlePart1='Solar Panels For'
        titleHighlight='Residential'
      />
      <ServicesBenefits
        title='Tailored Residential'
        titleHighlight='Solar Services'
        benefits={residentialBenefits}
      />
      <RooftopSolutions />
      <CallUsBanner bgImage={callUsBg} />
      <WhyUs whyUsDetails={residentialWhyUsStrings} />
      <Certificates />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={residentialFAQS} />
      <Form />
    </>
  );
};

export default Residential;
