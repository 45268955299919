import { ImQuotesLeft } from 'react-icons/im';

import PrevButton from 'components/shared/buttons/prev-button/prev-button.component';
import NextButton from 'components/shared/buttons/next-button/next-button.component';

import './testimonials.styles.scss';
import { colors } from 'theme/colors';
import { brandCertificates, testimonials } from 'constants/brand.constants';
import { FC, useState } from 'react';
import { Direction } from 'types/enums';
import ImageContainer from 'components/shared/image-container/image-container.component';
import classNames from 'classnames';

interface Props {
  hideCertificates?: boolean;
  showImage?: boolean;
}

const Testimonials: FC<Props> = ({ hideCertificates, showImage }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleTestimonialChange = (direction: Direction): void => {
    setSelectedIndex((prev) => {
      switch (direction) {
        case Direction.BACKWARD:
          return (prev - 1 + testimonials.length) % testimonials.length;
        case Direction.FORWARD:
          return (prev + 1) % testimonials.length;
      }
    });
  };

  const selectedTestimonial = testimonials[selectedIndex];

  return (
    <section className='testi-section'>
      <div className='testi-content'>
        <p className='testi-title'>CLIENTS</p>
        <h2 className='testi-summary'>
          What our <span>Customers Say</span>
        </h2>

        <div className='testi-carousel'>
          <div className='testi-carousel-arrow desktop'>
            <PrevButton
              onClick={() => handleTestimonialChange(Direction.BACKWARD)}
            />
          </div>

          <div className={classNames('testi-testimonial', { showImage })}>
            {showImage && (
              <div
                className='testi-image'
                style={{
                  backgroundImage: `url(${selectedTestimonial.clientImageUrl})`,
                }}
              ></div>
            )}

            <div className='testi-content'>
              <div className='testi-quotes'>
                <ImQuotesLeft color={colors.lightBlue} className='q-icon' />
              </div>
              <p className='testi-text'>{selectedTestimonial.testimonial}</p>
              <p className='testi-author'>
                {selectedTestimonial.clientName}
                {selectedTestimonial.clientDesignation ? ',' : ''}{' '}
                <span className='testi-author-designation'>
                  {selectedTestimonial.clientDesignation}
                </span>
              </p>
            </div>
          </div>
          <div className='testi-navigation-row mobile'>
            <PrevButton
              onClick={() => handleTestimonialChange(Direction.BACKWARD)}
            />
            <NextButton
              onClick={() => handleTestimonialChange(Direction.FORWARD)}
            />
          </div>

          <div className='testi-carousel-arrow desktop'>
            <NextButton
              onClick={() => handleTestimonialChange(Direction.FORWARD)}
            />
          </div>
        </div>

        {!hideCertificates && (
          <>
            <h5 className='testi-certificate-text'>
              Certifications upholding <strong>our quality standards</strong>
            </h5>

            <div className='certificates-row'>
              {brandCertificates.map((c) => (
                <div key={c.id} className='certificate'>
                  <ImageContainer
                    src={c.imageUrl}
                    alt={c.certificate}
                    height='68px'
                  />
                  <p className='certificate-type'>{c.certificate}</p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
