import { FC } from 'react';
import './card-tag.styles.scss';

interface CardTagProps {
  title: string;
}

const CardTag: FC<CardTagProps> = ({ title }) => {
  return <div className='card-tag'>{title}</div>;
};

export default CardTag;
