import { brandManufactures } from 'constants/brand.constants';
import './manufactures.styles.scss';
import NextButton from 'components/shared/buttons/next-button/next-button.component';

const Manufactures = () => {
  return (
    <section className='mf-section'>
      <div className='mf-row'>
        <p className='mf-title'>Our Manufacture</p>
        <h2 className='mf-summary'>
          Manufacturers of <span>High Quality Products</span>
        </h2>
        <div className='mf-manufactures'>
          {brandManufactures.map((m) => (
            <div
              key={m.id}
              className='mf-manufacture'
              style={{
                backgroundImage: `url(${m?.imageUrl})`,
              }}
            >
              <div className='mf-dc-gradient' />
              <div className='mf-texts-row'>
                <h5 className='mf-title'>{m.title.toLowerCase()}</h5>
                <NextButton color='white' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Manufactures;
