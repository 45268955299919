import { IoIosStarOutline } from 'react-icons/io';
import { GoThumbsup } from 'react-icons/go';
import { IoCallOutline } from 'react-icons/io5';

import './why‐us.styles.scss';
import { whyUsStrings } from 'constants/strings.constants';
import WhyUsCard from './why-us-card/why-us-card.component';
import { BrandWhyUs } from 'types/models';
import { FC } from 'react';

interface Props {
  whyUsDetails: BrandWhyUs[];
}

const WhyUs: FC<Props> = ({ whyUsDetails }) => {
  return (
    <section className='wu-section'>
      <div className='wu-content'>
        <div className='wu-texts'>
          <h2 className='wu-summary'>
            Why <span>Us?</span>
          </h2>
        </div>

        <div className='why-us-cards'>
          {whyUsDetails.map((detail) => (
            <WhyUsCard key={detail.title} {...detail} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
