import { SlArrowRightCircle } from 'react-icons/sl';
import { ButtonHTMLAttributes, FC } from 'react';

import './next-button.styles.scss';

interface Props {
  color?: string;
}

type LocalProps = ButtonHTMLAttributes<HTMLButtonElement> & Props;

const NextButton: FC<LocalProps> = ({ color, ...buttonProps }) => {
  return (
    <button className='next-btn' style={{ color }} {...buttonProps}>
      <SlArrowRightCircle className='nb-icon' color={color || 'black'} />
    </button>
  );
};

export default NextButton;
