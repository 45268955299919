import { FC, ReactNode } from 'react';
import './why-us-card.styles.scss';

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
}

const WhyUsCard: FC<Props> = ({ icon, title, description }) => {
  return (
    <div className='why-us-card'>
      <div className='wuc-icon'>{icon}</div>
      <h5 className='wuc-title'>{title}</h5>
      <p className='wuc-description'>{description}</p>
    </div>
  );
};

export default WhyUsCard;
