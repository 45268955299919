import { FC } from 'react';

import mLogo from 'assets/images/M.png';

import './about.styles.scss';
import classNames from 'classnames';

interface Props {
  title: string;
  titleHighlight: string;
  summary: string;
  image?: string;
  flip?: boolean;
}

const About: FC<Props> = ({
  title,
  titleHighlight,
  summary,
  image = '',
  flip,
}) => {
  return (
    <section className='ca-section'>
      <div className={classNames('ca-row', { flip })}>
        <div className='ca-texts'>
          <h2 className='ca-summary'>
            {title} <span>{titleHighlight}</span>
          </h2>
          <p className='ca-description'>{summary}</p>
        </div>

        <div className='ca-img'>
          <div
            className='ca-image'
            style={{ backgroundImage: `url(${image})` }}
          >
            <div
              className='ca-m-tag'
              style={{ backgroundImage: `url(${mLogo})` }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
