import { ToastContainer } from 'react-toastify';
import Header from 'components/layout/header/header.component';
import { Route, Routes } from 'react-router-dom';

import { appRoutes } from 'constants/routes';
import { toastContainerConfig } from 'utils/toast.utils';

import ThankYou from 'pages/thank-you.page';
import Footer from 'components/layout/footer/footer.component';
import Homepage from 'pages/home.page';
import Commercial from 'pages/commercial.page';
import Residential from 'pages/residential.page';
import SideNav from 'components/layout/side-nav/side-nav.component';
import Blog from 'pages/blog.page';
import HousingSocieties from 'pages/housing-societies.page';
import BlogDetailsPage from 'pages/blog-details.page';

import './App.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className='app-wrapper'>
      <div className='page-container'>
        <Header />
        <SideNav />
        <Routes>
          <Route path={appRoutes.thankYou.path} element={<ThankYou />} />
          <Route path={appRoutes.home.path} element={<Homepage />} />
          <Route path={appRoutes.commercial.path} element={<Commercial />} />
          <Route path={appRoutes.residential.path} element={<Residential />} />
          <Route path={`${appRoutes.blog.path}/:blogId`} element={<BlogDetailsPage />} />
          <Route path={appRoutes.blog.path} element={<Blog />} />
          <Route
            path={appRoutes.housingSocieties.path}
            element={<HousingSocieties />}
          />
        </Routes>
      </div>
      <div className='footer'>
        <Footer />
      </div>
      <ToastContainer {...toastContainerConfig} />
    </div>
  );
}

export default App;
