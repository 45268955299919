import { AppRoute } from "types/routes.types";

export const appRoutes: Record<string, AppRoute> = {
  home: { path: "/", title: "Home", quickLink: true },
  about: { path: "/about", title: "About Us", quickLink: true },
  services: {
    path: "/services",
    title: "Services",
    subRoutes: [
      {
        path: "/residential-rooftop-solar-panel",
        title: "Residential",
      },
      {
        path: "/commercial-rooftop-solar",
        title: "Commercial & Industrial",
      },
      {
        path: "/solar-panel-for-housing-society",
        title: "Housing Societies",
      },
      {
        path: "/outer-space",
        title: "Outer Space",
      },
      {
        path: "/megawatt-solar-power-plant",
        title: "Megawatt Projects",
      },
    ],
    quickLink: true,
  },
  manufacture: {
    path: "/manufacture",
    title: "Manufacture",
    subRoutes: [],
    quickLink: true,
  },
  projects: { path: "/projects", title: "Projects", quickLink: true },
  blog: { path: "/blog", title: "Blog", quickLink: true },
  thankYou: { path: "/thank-you", title: "Thank You", quickLink: false },
  contactUs: { path: "/contact-us", title: "Contact Us", quickLink: false },
  commercial: {
    path: "/commercial-rooftop-solar",
    title: "Commercial",
    quickLink: false,
  },
  residential: {
    path: "/residential-rooftop-solar-panel",
    title: "Residential",
    quickLink: false,
  },
  housingSocieties: {
    path: "/solar-panel-for-housing-society",
    title: "Housing Societies",
    quickLink: false,
  },
  megawatt: {
    path: "/megawatt-solar-power-plant",
    title: "Megawatt Projects",
    quickLink: false,
  },
};

export const externalRoutes = {
  facebook: "",
  instagram: "",
  linkedIn: "",
  process: "",
};
