import { brandSolutions } from 'constants/brand.constants';
import { useState } from 'react';
import { BrandSolution } from 'types/models';
import './solutions.styles.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Solutions = () => {
  const [selectedSolution, setSelectedSolution] = useState<BrandSolution>(
    brandSolutions[0]
  );

  const navigate = useNavigate();

  const handleSolutionChange = (solution: BrandSolution): void => {
    setSelectedSolution(solution);
  };

  return (
    <section className='sl-section'>
      <div className='sl-row'>
        <p className='sl-title'>Our Solutions</p>
        <h2 className='sl-summary'>
          Innovative Solar <span>Solutions for Every Need</span>
        </h2>

        <div className='sl-chips'>
          {brandSolutions.map((s) => (
            <div
              onClick={() => handleSolutionChange(s)}
              key={s.id}
              className={classNames('sl-chip', {
                active: selectedSolution.id === s.id,
              })}
            >
              {s.title}
            </div>
          ))}
        </div>

        <div
          className='sl-detail-card'
          style={{ backgroundImage: `url(${selectedSolution?.imageUrl})` }}
        >
          <div className='sl-dc-gradient' />
          <div className='sl-dc-texts'>
            <p className='sl-description'>{selectedSolution.description}</p>
            <span
              className='sl-know-more'
              onClick={() => navigate(selectedSolution.route)}
            >
              Know More
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
