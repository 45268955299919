import homeUrl from 'assets/images/commercial/house.png';

import { brandOfferings } from 'constants/brand.constants';

import ImageContainer from 'components/shared/image-container/image-container.component';
import Offering from './offering/offering.component';

import './tailored-offerings.styles.scss';

const TailoredOfferings = () => {
  return (
    <section className='to-section'>
      <div className='to-content'>
        <div className='toc-left'>
          <h2 className='to-summary'>
            Our Tailored<span> Solar Offerings</span>
          </h2>
          <div className='toc-img'>
            <ImageContainer src={homeUrl} alt='' width='100%' />
          </div>
        </div>
        <div className='toc-right'>
          {brandOfferings.map((o) => (
            <Offering key={o.id} title={o.title} description={o.description} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TailoredOfferings;
