import { GoThumbsup } from 'react-icons/go';
import { IoIosStarOutline } from 'react-icons/io';
import { PiCertificateLight } from 'react-icons/pi';

import { BrandFaq, BrandWhyUs } from 'types/models';

export const commercialFaqs: BrandFaq[] = [
  {
    id: 'faq-1',
    title: 'How long does it take to install a commercial solar system?',
    description:
      'The installation timeline varies based on the size and complexity of the project. Typically, our commercial solar energy systems take 2-4 weeks from design to commissioning.',
  },
  {
    id: 'faq-2',
    title: 'What are the maintenance requirements for commercial solar panels?',
    description:
      'Maintenance is minimal. We provide regular checks and cleaning services to ensure your commercial solar panels operate at peak efficiency, maximizing energy output and longevity.',
  },
  {
    id: 'faq-3',
    title: 'Can solar panels handle the energy needs of large businesses?',
    description:
      'Yes, our industrial solar power installations are designed to meet the energy demands of businesses of all sizes, providing reliable and scalable power solutions.',
  },
];

export const commercialWhyUsStrings: BrandWhyUs[] = [
  {
    title: 'Proven Expertise in Commercial Solar',
    icon: <IoIosStarOutline />,
    description:
      'With years of experience, we are trusted commercial solar installers delivering tailored commercial solar systems that maximize efficiency and ROI.',
  },
  {
    title: 'Comprehensive End-to-End Service',
    icon: <GoThumbsup />,
    description:
      'From consultation and design to installation and maintenance, our all-inclusive approach ensures a seamless transition to sustainable energy for industries.',
  },
  {
    title: 'Certified and Quality-Assured Solutions',
    icon: <PiCertificateLight />,
    description:
      'Our commercial solar power plants are backed by industry-leading certifications, guaranteeing safety, durability, and optimal performance.',
  },
];
