import { FC } from 'react';
import './benefit-card.styles.scss';
import { Benefit } from 'types/models';

interface Props {
  benefit: Benefit;
  index: number;
}

const BenefitCard: FC<Props> = ({ benefit, index }) => {
  const { title, description, imageUrl } = benefit;

  return (
    <div className='sb-card'>
      <div className='sbc-row'>
        <div className='sbc-title'>
          <p className='sbc-index'>
            {index.toString().length > 1 ? index : `0${index}`}
          </p>
          <h4 className='sbc-title-text'>{title}</h4>
        </div>
        <p className='sbc-description'>{description}</p>
      </div>
      {imageUrl && <div className='sbc-img'></div>}
    </div>
  );
};

export default BenefitCard;
