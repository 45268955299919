import { blogs } from 'constants/brand.constants';

import BlogCard from 'components/homepage/blogs/blog-card/blog-card.component';

import './related-blogs.styles.scss';

const RelatedBlogs = () => {
  return (
    <section className='bdc-related-posts'>
      <h3 className='bdc-related-posts-title'>Related Posts</h3>
      <div className='bdc-rp-grid'>
        {blogs.slice(0, 3).map((blog) => (
          <BlogCard key={blog.id} blog={blog} showTag />
        ))}
      </div>
    </section>
  );
};

export default RelatedBlogs;
