import { FC } from 'react';

import { SolarSolution } from 'types/models';

import ImageContainer from 'components/shared/image-container/image-container.component';

import './rooftop-solution-card.styles.scss';

interface Props {
  solution: SolarSolution;
}

const RooftopSolutionCard: FC<Props> = ({ solution }) => {
  const { title, details, imageUrl } = solution;

  return (
    <div className='blog-card'>
      <div className='bc-image'>
        <ImageContainer src={imageUrl} alt={title} width='100%' height='100%' />
      </div>
      <div className='bc-content'>
        <h2 className='bc-title'>{title}</h2>
        <ul style={{ listStyle: 'inside' }}>
          {details.map((d) => (
            <li key={d} className='bc-description'>
              {d}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RooftopSolutionCard;
