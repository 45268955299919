import heroBgUrl from 'assets/images/commercial/hero.jpeg';
import earthBgUrl from 'assets/images/commercial/earth.png';

import About from 'components/shared/about/about.component';
import CallUsBanner from 'components/shared/call-us-banner/call-us-banner.component';
import Certificates from 'components/commercial/certificates/certificates.component';
import Form from 'components/commercial/form/form.component';
import OurProjects from 'components/commercial/our-projects/our-projects.component';
import SolarBenefits from 'components/commercial/solar‐benefits/solar‐benefits.component';
import TailoredOfferings from 'components/commercial/tailored-offerings/tailored-offerings.component';
import WhyUs from 'components/commercial/why‐us/why‐us.component';
import Faqs from 'components/homepage/faqs/faqs.component';
import Hero from 'components/shared/hero/hero.component';
import Testimonials from 'components/testimonials/testimonials.components';
import {
  commercialFaqs,
  commercialWhyUsStrings,
} from 'constants/commercial.constants';

const Commercial = () => {
  return (
    <>
      <Hero
        backgroundUrl={heroBgUrl}
        titlePart1='Solar Panels For'
        titleHighlight='Commercial & Industrial Building'
      />
      <About
        title='Optimize Your Business with'
        titleHighlight='Solar Energy'
        summary='Enhance your business’s energy efficiency with commercial rooftop
            solar solutions. By installing solar panels for business, you reduce
            operational costs and contribute to a sustainable future. Our
            industrial solar power installations are designed to optimize energy
            use, ensuring maximum savings and minimal environmental impact.
          '
      />
      <CallUsBanner bgImage={earthBgUrl} />
      <WhyUs whyUsDetails={commercialWhyUsStrings} />
      <Certificates />
      <TailoredOfferings />
      <SolarBenefits />
      <Form />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={commercialFaqs} />
    </>
  );
};

export default Commercial;
