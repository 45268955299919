import { SlArrowLeftCircle } from 'react-icons/sl';
import { ButtonHTMLAttributes, FC } from 'react';

import './prev-button.styles.scss';

interface Props {
  color?: string;
}

type LocalProps = ButtonHTMLAttributes<HTMLButtonElement> & Props;

const PrevButton: FC<LocalProps> = ({ color, ...buttonProps }) => {
  return (
    <button className='prev-btn' style={{ color }} {...buttonProps}>
      <SlArrowLeftCircle className='pb-icon' color={color || 'black'} />
    </button>
  );
};

export default PrevButton;
