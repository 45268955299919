import classNames from 'classnames';
import { useState } from 'react';
import { IoChevronDown, IoCloseOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import { appRoutes } from 'constants/routes';
import brandLogoUrl from 'assets/images/brand-logo.png';
import { useSideNav } from 'providers/side-nav.provider';

import ImageContainer from 'components/shared/image-container/image-container.component';

import './side-nav.styles.scss';
import { brandName } from 'constants/strings.constants';
import { AppRoute } from 'types/routes.types';

const SideNav = () => {
  const [openSubRoutes, setOpenSubRoutes] = useState<boolean>(false);
  const { isSideNavOpen, toggleSideNav } = useSideNav();

  const toggleSubRoutes = () => setOpenSubRoutes((prev) => !prev);

  const handleNavigation = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: AppRoute
  ) => {
    if (route.subRoutes?.length) {
      evt.preventDefault();
      toggleSubRoutes();
    }
  };

  return (
    <nav className={classNames('sn-container', { open: isSideNavOpen })}>
      <div className={classNames('sn', { open: isSideNavOpen })}>
        <div className='sn-header'>
          <NavLink to={appRoutes.home.path}>
            <div className='sn-brand'>
              <ImageContainer
                height='32px'
                src={brandLogoUrl}
                alt={brandName}
                objectFit='contain'
              />
            </div>
          </NavLink>

          <IoCloseOutline
            size='32px'
            className='h-mobile-menu'
            onClick={toggleSideNav}
          />
        </div>
        <ul className='links'>
          {Object.values(appRoutes)
            .filter((r) => r.quickLink)
            .map((route) => (
              <li key={route.path} className='nav-link-wrapper'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'nav-link active' : 'nav-link'
                  }
                  onClick={(e) => handleNavigation(e, route)}
                  to={route.path}
                >
                  {route.title}
                  {!!route.subRoutes?.length && <IoChevronDown />}
                </NavLink>
                {!!route.subRoutes?.length && openSubRoutes && (
                  <div className='sub-routes'>
                    {route.subRoutes?.map((sb) => (
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'sub-route active' : 'sub-route'
                        }
                        to={sb.path}
                      >
                        {sb.title}
                      </NavLink>
                    ))}
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;
