import { toast, ToastOptions, ToastPosition } from 'react-toastify';

type toastType = 'success' | 'warning' | 'error' | 'info';

const toastStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  marginRight: '10px',
};

const toastContainerStyles: React.CSSProperties = {
  width: 'auto',
};

export const toastConfig: ToastOptions = {
  position: 'top-center' as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  style: toastStyles,
  className: 'rt_toast',
};

export const toastContainerConfig = {
  position: 'top-center' as ToastPosition,
  autoClose: 1000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: false,
  rtl: false,
  draggable: false,
  pauseOnHover: true,
  style: toastContainerStyles,
};

export const notify = (text: string, type?: toastType) => {
  switch (type) {
    case 'success':
      return toast.success(text, toastConfig);
    case 'warning':
      return toast.warn(text, toastConfig);
    case 'info':
      return toast.info(text, toastConfig);
    case 'error':
      return toast.error(text, toastConfig);
    default:
      return toast(text, toastConfig);
  }
};
