import { GoCheckCircleFill } from 'react-icons/go';
import { colors } from 'theme/colors';
import vanUrl from 'assets/images/van.png';

import './thank-you.styles.scss';
import ImageContainer from 'components/shared/image-container/image-container.component';

const ThankYouSection = () => {
  return (
    <section className='thank-you-section'>
      <GoCheckCircleFill fontSize='100px' color={colors.lightBlue} />
      <h2 className='ty-text'>Thank You</h2>
      <h3 className='ty-subtext'>Our team will contact you shortly</h3>
      <ImageContainer src={vanUrl} alt='mpl-van' width='200px' />
    </section>
  );
};

export default ThankYouSection;
