import { useState } from 'react';

import { Direction } from 'types/enums';
import { brandProjects } from 'constants/brand.constants';
import mLogo from 'assets/images/M.png'

import PrevButton from 'components/shared/buttons/prev-button/prev-button.component';
import NextButton from 'components/shared/buttons/next-button/next-button.component';

import './projects.styles.scss';

const Projects = () => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);

  const handleSolutionChange = (direction: Direction): void => {
    setSelectedProjectIndex((prev) => {
      switch (direction) {
        case Direction.BACKWARD:
          return Math.max(0, prev - 1);
        case Direction.FORWARD:
          return Math.min(prev + 1, brandProjects.length - 1);
      }
    });
  };

  const selectedProject = brandProjects[selectedProjectIndex];

  return (
    <section className='prj-section'>
      <div className='prj-content'>
        <div className='prj-texts'>
          <p className='prj-sc-title'>PROJECTS</p>
          <h2 className='prj-summary'>
            Diverse <span>Solar Projects</span>
          </h2>
        </div>

        <div className='prj-details-row'>
          <div>
            <h4 className='prj-title'>{selectedProject.title}</h4>

            <ul className='prj-details'>
              {selectedProject.projectDetails.map((d) => (
                <li key={d} className='prj-detail'>
                  {d}
                </li>
              ))}
            </ul>

            <div className='prj-know-more-text'>Read More</div>
          </div>

          <div className='prj-img'>
            <div
              className='prj-image'
              style={{ backgroundImage: `url(${selectedProject.imageUrl})` }}
            >
              <div className='prj-m-tag' style={{backgroundImage: `url(${mLogo})`}}></div>
            </div>
            <div className='prj-navigation-row desktop'>
              <PrevButton
                onClick={() => handleSolutionChange(Direction.BACKWARD)}
              />
              <NextButton
                onClick={() => handleSolutionChange(Direction.FORWARD)}
              />
            </div>
          </div>
        </div>

        <div className='prj-navigation-row mobile'>
          <PrevButton
            onClick={() => handleSolutionChange(Direction.BACKWARD)}
          />
          <NextButton onClick={() => handleSolutionChange(Direction.FORWARD)} />
        </div>
      </div>
    </section>
  );
};

export default Projects;
