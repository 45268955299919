import CardTag from 'components/shared/card-tag/card-tag.component';

import './blog-details.styles.scss';

const BlogDetails = () => {
  return (
    <section className='bd-wrapper'>
      <h1 className='bd-title'>The Benefit of Residential Solar Systems</h1>
      <div className='bd-type'>
        <p className='bd-type-text'>Residential Solar</p>
        <p className='bd-separator'>&#8226;</p>
        <p className='bd-created-at'></p>
      </div>
      <div className='bd-img' />
      <div className='bd-row'>
        <div className='bd-content-column'>
          <p className='bd-intro'>
            As the demand for sustainable energy solutions grows, residential
            solar systems have become an increasingly popular choice for
            homeowners. These systems not only offer a renewable source of
            energy but also provide significant financial and environmental
            benefits.
          </p>

          <div className='bd-content'>
            <h4 className='bdc-title'>1. Cost savings</h4>
            <p className='bdc-description'>
              One of the primary advantages of installing solar panels is the
              potential for substantial cost savings. By generating your own
              electricity, you can reduce or even eliminate your monthly energy
              bills. Additionally, many regions offer net metering programs that
              allow homeowners to sell excess energy back to the grid, further
              offsetting costs. Over time, the savings on utility bills can help
              homeowners recoup the initial investment in solar panels, often
              within a few years.
            </p>
          </div>

          <div className='bdc-conclusion bd-content'>
            <h3 className='bdc-conc-title'>Conclusion</h3>
            <p className='bdc-conclusion-text'>
              Residential solar systems offer a multitude of benefits, from cost
              savings and increased property value to environmental
              sustainability and energy independence. With ongoing advancements
              in solar technology and the availability of government incentives,
              now is an excellent time for homeowners to consider making the
              switch to solar power. Not only can you save money, but you can
              also make a positive impact on the planet, all while enjoying
              reliable and low-maintenance energy for years to come.
            </p>
          </div>
        </div>

        <div className='bd-table-of-content'>
          <h5 className='bd-toc-title'>Table of Content</h5>
          <ul className='bd-content-list'>
            <li>Introduction</li>
            <li>Cost Saving</li>
            <li>Conclusion</li>
          </ul>
        </div>
      </div>

      <div className='bd-tags'>
        <CardTag title='Saving from solar panels' />
        <CardTag title='Saving from solar panels' />
        <CardTag title='Saving from solar panels' />
        <CardTag title='Saving from solar panels' />
      </div>
    </section>
  );
};

export default BlogDetails;
