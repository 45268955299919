import { ButtonVariant } from 'types/enums';

import ContactUsButton from 'components/shared/contact-us-button/contact-us-button.component';

import './call-us-banner.styles.scss';
import { FC } from 'react';

interface Props {
  bgImage: string;
}

const CallUsBanner: FC<Props> = ({ bgImage }) => {
  return (
    <div className='cub-wrapper'>
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className='cub-content'
      >
        <p className='cub-text'>
          Call Experienced Solar Consultants In your Area
        </p>

        <div className='cub-button-div'>
          <ContactUsButton variant={ButtonVariant.outlined} />
        </div>
      </div>
    </div>
  );
};

export default CallUsBanner;
