import { useState, createContext, useContext, PropsWithChildren } from 'react';

interface SideNavContextValue {
  isSideNavOpen: boolean;
  toggleSideNav: () => void;
}

const SideNavContext = createContext<SideNavContextValue | null>(null);

const SideNavProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <SideNavContext.Provider value={{ isSideNavOpen, toggleSideNav }}>
      {children}
    </SideNavContext.Provider>
  );
};

const useSideNav = () => {
  const context = useContext(SideNavContext);
  if (!context) {
    throw new Error('useSideNav must be used within a SideNavProvider');
  }
  return context;
};

export { SideNavProvider, useSideNav };
