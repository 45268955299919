import earthBgUrl from 'assets/images/commercial/earth.png';

import About from 'components/shared/about/about.component';
import Certificates from 'components/commercial/certificates/certificates.component';
import Form from 'components/commercial/form/form.component';
import OurProjects from 'components/commercial/our-projects/our-projects.component';
import SolarBenefits from 'components/commercial/solar‐benefits/solar‐benefits.component';
import TailoredOfferings from 'components/commercial/tailored-offerings/tailored-offerings.component';
import WhyUs from 'components/commercial/why‐us/why‐us.component';
import Faqs from 'components/homepage/faqs/faqs.component';
import CallUsBanner from 'components/shared/call-us-banner/call-us-banner.component';
import Hero from 'components/shared/hero/hero.component';
import Testimonials from 'components/testimonials/testimonials.components';
import {
  housingBenefits,
  housingSocietiesFAQS,
  housingSocietiesWhyUsStrings,
} from 'constants/housing-societies.constants';
import ServicesBenefits from 'components/shared/service-benefits/service-benefits.component';

const HousingSocieties = () => {
  return (
    <>
      <Hero
        backgroundUrl='https://res.cloudinary.com/dsmpun8yd/image/upload/v1722790796/Rectangle_j4bwpy.png'
        titlePart1='Solar Panels For'
        titleHighlight='Housing Societies'
      />
      <About
        title='Empowering Housing Societies with'
        titleHighlight='Solar Energy'
        summary={`Solar power for housing societies is more than just an energy solution; it's a step towards a sustainable future. By installing solar panels for housing societies, residents can harness clean, renewable energy right from their rooftops. Solar energy for housing societies not only reduces electricity bills but also promotes a greener environment, making your society a model for sustainable living.`}
      />
      <ServicesBenefits
        benefits={housingBenefits}
        title='Benefit of Solar Power for '
        titleHighlight='Housing Societies'
      />
      <About
        flip
        title='Recover Your Solar'
        titleHighlight='Investment Efficiently'
        summary={`Investing in solar power for housing societies is a wise decision that pays off over time. The affordability of solar for housing societies combined with available government subsidies makes it easier to recover your investment. Affordable solar for residential complexes ensures that your upfront costs are minimized, and the returns on investment are maximized through consistent energy savings.`}
      />
      <CallUsBanner bgImage={earthBgUrl} />
      <WhyUs whyUsDetails={housingSocietiesWhyUsStrings} />
      <Certificates />
      <OurProjects />
      <Testimonials hideCertificates showImage />
      <Faqs faqs={housingSocietiesFAQS} />
      <Form />
    </>
  );
};

export default HousingSocieties;
