import { FC } from 'react';

import ContactUsButton from 'components/shared/contact-us-button/contact-us-button.component';

import './hero.styles.scss';

interface Props {
  titlePart1: string;
  titleHighlight: string;
  subTitle?: string;
  backgroundUrl?: string;
  hideContactUs?: boolean;
}

const Hero: FC<Props> = ({
  titlePart1,
  titleHighlight,
  subTitle,
  backgroundUrl,
  hideContactUs,
}) => {
  return (
    <section className='landing-wrapper'>
      <div
        className='l-bg'
        style={{ backgroundImage: `url(${backgroundUrl})` }}
      />
      <h3 className='l-heading'>
        {titlePart1}
        <br />
        <span>{titleHighlight}</span>
      </h3>
      <p className='l-subhead'>{subTitle}</p>

      {!hideContactUs && (
        <div className='l-cta'>
          <ContactUsButton />
        </div>
      )}
    </section>
  );
};

export default Hero;
