import { useState, FormEventHandler, useRef, ChangeEvent } from 'react';
import classNames from 'classnames';
import { MdArrowRightAlt } from 'react-icons/md';
import { Input } from '@material-tailwind/react';

import solarBg from 'assets/images/solar.png';
import { monthlyBillRange } from 'constants/brand.constants';

import CustomButton from 'components/shared/buttons/custom-button/custom-button.component';

import './form.styles.scss';
import { notify } from 'utils/toast.utils';

const Form = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState<any>({});
  const [selectedBillRange, setSelectedBillRange] = useState<string>('');

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!selectedBillRange) {
      notify('Please choose a Monthly Bill Range', 'error');
      return;
    }
    setLoading(true);

    const formData = new FormData();
    Object.keys(formDetails).forEach((key) => {
      formData.append(key, formDetails[key]);
    });

    try {
      await fetch(
        'https://script.google.com/macros/s/AKfycbz3yuC7lNUnleQvL8Gky9sX6D2jvm-abrNSvXFLlRJFIHdoc2qpT_eWQEocxJMIpPgI/exec',
        {
          method: 'POST',
          body: formData,
        }
      );

      formRef.current?.reset();
      setSelectedBillRange('');
      notify('We have received your details', 'success');
    } catch (error) {
      notify('Something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: ChangeEvent) => {
    const { value, id } = e.target as HTMLInputElement;

    setFormDetails((prev: any) => ({ ...prev, [id]: value }));
  };

  const handleMonthlyBillSelect = (v: string) => {
    setFormDetails((prev: any) => ({ ...prev, Bills: v }));
    setSelectedBillRange(v);
  };

  return (
    <section className='form-section'>
      <div className='form-content'>
        <div
          className='form-bg'
          style={{ backgroundImage: `url(${solarBg})` }}
        />
        <div className='form-texts'>
          <h3 className='form-heading'>
            Schedule a <strong>Free Consultation</strong>, with us{' '}
            <strong>Today !!</strong>
          </h3>
          <p className='form-summary'>
            Discover your ideal solar solution with a complimentary consultation
            today. We specialize in recommending the best design, service, and
            maintenance for your needs
          </p>
        </div>
        <form className='form' ref={formRef} onSubmit={handleSubmit}>
          <Input
            id='Name'
            variant='standard'
            label='Full Name'
            color='white'
            required
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            crossOrigin={false}
            onChange={handleChange}
          />
          <Input
            onChange={handleChange}
            id='Number'
            variant='standard'
            label='Contact Number'
            color='white'
            required
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            crossOrigin={false}
          />
          <div>
            <p className='form-input-label'>Monthly Electricity Bill *</p>
            <div className='ranges-row'>
              {monthlyBillRange.map((bill) => (
                <div
                  onClick={() => handleMonthlyBillSelect(bill.value)}
                  key={bill.value}
                  className={classNames('range-option', {
                    active: bill.value === selectedBillRange,
                  })}
                >
                  {bill.value}
                </div>
              ))}
            </div>
          </div>
          <Input
            variant='standard'
            onChange={handleChange}
            label='City'
            id='City'
            color='white'
            required
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            crossOrigin={false}
          />
          <Input
            variant='standard'
            label='Email'
            id='Email'
            color='white'
            required
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            onChange={handleChange}
            crossOrigin={false}
          />
          <div className='submit-btn'>
            <CustomButton
              loading={loading}
              endIcon={<MdArrowRightAlt fontSize='20px' color='inherit' />}
              color='primary'
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Form;
