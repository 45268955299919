import { solarBenefits } from 'constants/brand.constants';
import BenefitCard from './benefit-card/benefit-card.component';

import './solar‐benefits.styles.scss';

const SolarBenefits = () => {
  return (
    <section className='sb-section'>
      <div className='sb-content'>
        <div className='sb-texts'>
          <h2 className='sb-summary'>
            Benefits of Solar Panel <span>Commercial Services</span>
          </h2>
        </div>

        <div className='benefits-cards'>
          {solarBenefits.map((b, i) => (
            <BenefitCard key={b.id} benefit={b} index={i + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolarBenefits;
