import { blogs } from 'constants/brand.constants';
import './blogs.styles.scss';
import BlogCard from './blog-card/blog-card.component';
import CustomButton from 'components/shared/buttons/custom-button/custom-button.component';

const Blogs = () => {
  return (
    <section className='blogs-section'>
      <div className='blogs-row'>
        <p className='blogs-title'>BLOGS</p>
        <h2 className='blogs-summary'>
          Stay Updated with <span>Our Latest Insights</span>
        </h2>

        <div className='blogs-grid'>
          {blogs.slice(0, 3).map((b) => (
            <BlogCard key={b.id} blog={b} />
          ))}
        </div>
        <div className='b-cta-row'>
          <CustomButton>View All</CustomButton>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
