import './faqs.styles.scss';
import Faq from './faq/faq.component';
import { BrandFaq } from 'types/models';
import { FC } from 'react';

interface Props {
  faqs: BrandFaq[];
}

const Faqs: FC<Props> = ({ faqs }) => {
  return (
    <section className='faq-section'>
      <div className='faq-content'>
        <h2 className='faq-section-title'>Frequently Asked Question</h2>
        <div className='faqs-column'>
          {faqs.map((f) => (
            <Faq key={f.id} faq={f} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
