import earthBgUrl from 'assets/images/commercial/earth.png';
import heroBg from 'assets/images/blogs/hero-bg.png';

import LatestBlogs from 'components/blog/latest-blog/latest-blog.component';
import CallUsBanner from 'components/shared/call-us-banner/call-us-banner.component';
import Hero from 'components/shared/hero/hero.component';
import AllBlogs from 'components/blog/all-blogs/all-blogs.component';

const Blog = () => {
  return (
    <>
      <Hero
        backgroundUrl={heroBg}
        titleHighlight='Solar Insights'
        titlePart1='Our Latest'
        hideContactUs
      />
      <LatestBlogs />
      <AllBlogs />
      <CallUsBanner bgImage={earthBgUrl} />
    </>
  );
};

export default Blog;
